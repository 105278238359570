<template>
  <div class="container">
    <div class="content">
      <div class="title">
        <div class="title-top">
          <img src="../assets/news/icon.png"
               class="img-icon"
               alt="">
          <h1 class="title-h1">NEWS</h1>
        </div>
        <div class="title-middle">
          <h1 class="title-h1">&MEDIA</h1>
        </div>
        <div class="title-bottom"
             v-if="$store.state.isZH">
          新闻与媒体
        </div>
      </div>

      <div class="works-show">
        <div class="show-title"
             v-if="$store.state.isZH">
          <h3 class="h3-title">2023年 国际原料药展会-</h3>
          <div class="desc">欧瑞捷参展动态</div>
        </div>
        <div class="show-title"
             v-if="!$store.state.isZH">
          <h3 class="h3-title">CPhI China</h3>
          <h3 class="h3-title">in Shanghai</h3>
          <h3 class="h3-title">Jun. 19th-21st, 2023</h3>
        </div>
        <div class="show-desc"
             v-if="$store.state.isZH">欧瑞捷每年定期参加国内外的大型原料药展会，努力把产品推向全球各地。继VIV Asia之后，2023年欧瑞捷还将陆续参加2023 CPHI China、2023 CPHI Worldwide、2023 CPHI India等一系列行业盛会，真诚期待与您相约！</div>
        <div class="time-line"
             :class="$store.state.isZH? '' : 'ENtime-line' ">
          <el-timeline>
            <el-timeline-item color="#fff"
                              v-for="(activity, index) in activities"
                              :key="index"
                              :timestamp="activity.timestamp">
              {{activity.content}}
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'News',
  computed: {
    activities () {
      return [{
        content: this.$t('news.content1'),
        timestamp: this.$t('news.time1')
      }, {
        content: this.$t('news.content2'),
        timestamp: this.$t('news.time2')
      }, {
        content: this.$t('news.content3'),
        timestamp: this.$t('news.time3')
      }, {
        content: this.$t('news.content4'),
        timestamp: this.$t('news.time4')
      }]
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-timeline-item__timestamp {
  font-size: 22px;
}
::v-deep .el-timeline {
  font-size: 24px;
}
.container {
  width: 100%;
  height: 100%;
  .content {
    width: 100%;
    height: 100%;
    padding: 22% 15% 0;
    background: url("../assets/news/bgc.png") no-repeat;
    background-size: cover;
    box-sizing: border-box;
    .title {
      color: #fff;
      padding-bottom: 5%;
      box-sizing: border-box;
      .img-icon {
        height: 65px;
        padding-right: 6%;
      }
      .title-top,
      .title-middle {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      .title-h1 {
        font-size: 70px;
      }
      .title-bottom {
        display: flex;
        justify-content: flex-end;
        font-size: 47px;
        font-weight: bold;
      }
    }

    .works-show {
      color: #fff;
      width: 50%;
      padding-bottom: 15%;
      .show-title {
        padding-bottom: 10%;
        .h3-title {
          font-size: 35px;
        }
        .desc {
          padding-top: 3%;
          font-size: 28px;
        }
      }
      .show-desc {
        font-size: 18px;
        padding-bottom: 10%;
      }
      .ENtime-line {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
::v-deep .el-timeline-item__content {
  color: #fff;
}
::v-deep .el-timeline-item__timestamp.is-bottom {
  color: #fff;
}

@media only screen and (max-width: 500px) {
  ::v-deep .el-timeline {
    font-size: 17px;
  }
  ::v-deep .el-timeline-item__timestamp {
    font-size: 16px;
  }
  .container {
    .content {
      padding: 22% 5%;
      .title {
        .title-top {
          .img-icon {
            width: 30px;
            height: 30px;
          }
        }
        .title-h1 {
          font-size: 30px !important;
        }

        .title-bottom {
          font-size: 19px;
        }
      }
    }
  }
  // 内容
  .container .content .works-show {
    width: 88%;
    .show-title {
      .h3-title {
        font-size: 20px;
      }
      .desc {
        font-size: 16px;
      }
    }
    .show-desc {
      font-size: 13px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .container {
    .content {
      .title {
        .title-h1 {
          font-size: 55px;
        }
        .img-icon {
          width: 50px;
          height: 30px;
        }
        .title-bottom {
          font-size: 23px;
        }
      }
    }
  }

  // 内容
  .container .content .works-show {
    width: 88%;
    .show-title {
      .h3-title {
        font-size: 20px;
      }
      .desc {
        font-size: 16px;
      }
    }
    .show-desc {
      font-size: 13px;
    }
  }
}
</style>